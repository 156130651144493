@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'https://fonts.googleapis.com/css?family=Reenie+Beanie';
@font-face {
	font-family: 'URW DIN Arabic';
	src: url('/src/URW_DIN.ttf');
	font-weight: 400;
	font-style: normal;
}
body {
	margin: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #12141d;
	color: white;
	padding: 0 20px;
}
